@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    :root {
        --background: 210 40% 98%;
        --foreground: 222 47% 11%;

        --card: 0 0% 100%;
        --card-foreground: 222 47% 11%;

        --popover: 0 0% 100%;
        --popover-foreground: 222 47% 11%;

        --primary: 221 83% 53%;
        --primary-foreground: 210 40% 98%;

        --secondary: 214 32% 91%;
        --secondary-foreground: 222 47% 11%;

        --muted: 214 32% 91%;
        --muted-foreground: 215 25% 27%;

        --accent: 210 40% 96%;
        --accent-foreground: 222 47% 11%;

        --success: 142 76% 36%;
        --success-foreground: 210 40% 98%;

        --destructive: 0 84% 60%;
        --destructive-foreground: 210 40% 98%;

        --border: 214 32% 91%;
        --input: 214 32% 91%;
        --ring: 221 83% 53%;

        --radius: 0.5rem;
    }

    .dark {
        --background: 0 0% 4%;
        --foreground: 0 0% 98%;

        --card: 0 0% 6%;
        --card-foreground: 0 0% 98%;

        --popover: 0 0% 6%;
        --popover-foreground: 0 0% 98%;

        --primary: 221 83% 53%;
        --primary-foreground: 0 0% 98%;

        --secondary: 0 0% 13%;
        --secondary-foreground: 0 0% 98%;

        --muted: 0 0% 13%;
        --muted-foreground: 0 0% 63.9%;

        --accent: 0 0% 13%;
        --accent-foreground: 0 0% 98%;

        --success: 142 72% 50%;
        --success-foreground: 0 0% 4%;

        --destructive: 0 84% 60%;
        --destructive-foreground: 0 0% 98%;

        --border: 0 0% 13%;
        --input: 0 0% 13%;
        --ring: 221 83% 53%;
    }
}

@layer base {
    * {
        @apply border-border;
    }
    body {
        @apply bg-background text-foreground;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-text-fill-color: hsl(var(--foreground)) !important;
        -webkit-box-shadow: 0 0 0 30px hsl(var(--background)) inset !important;
        transition-delay: 9999s;
        transition-property: background-color, color;
    }

    :focus-visible {
        @apply outline-none ring-2 ring-primary ring-offset-2;
    }
}

html {
    scroll-behavior: smooth;
    scrollbar-gutter: stable;
}
